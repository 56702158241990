/**
 * Switch / switcher
 *
 * Makes our on/off switches accessible via spacebar toggle
 *
 */

export default {
	init () {
		const label = document.querySelectorAll('label.switch');

		label.forEach((elem) => {
			elem.addEventListener('keypress', (evt) => {
				if (evt.key === ' ') {
					const checkbox = elem.querySelector('input[type=checkbox]');
					if (!checkbox.disabled) {
						checkbox.checked = !checkbox.checked;
						evt.preventDefault();
					}
				}
			});
		});
	}
};
