
<template>
	<div class="chat__content">
		<div class="kitchensink">
			<slot
				name="trigger"
				:toggle="toggleDriftChat"
				:load="loadDrift"
				:show="showDrift"
				:open="openDrift"
				:hide="hideDrift"
			>
			</slot>
		</div>
	</div>
</template>

<script>
	import analytics from 'helpers/analytics';
	import driftHelper from 'helpers/drift';
	import driftEventHandler from 'helpers/drift-event-handler';

	export default {
		mounted () {
			if(!analytics.isLoaded()) {
				analytics.load();
			}
		},

		methods: {
			loadDrift (loading) {
				if(typeof drift != 'undefined') {
					this.openDrift();
					this.$emit('did-load');
				} else {
					driftHelper.load();
					drift.on('ready', (api, payload) => {
						this.openDrift();
						driftEventHandler.init();
						this.$emit('did-load');
						drift.on('chatClose', () => {
							this.$emit('show-support-shell-trigger');
						});
					});
				}
			},

			showDrift () {
				driftHelper.show();
			},

			openDrift () {
				driftHelper.openChat();
			},

			hideDrift () {
				driftHelper.hide();
			},

			toggleDriftChat () {
				driftHelper.toggleChat();
			}
		}
	};
</script>
