
import $ from 'jquery';

export default {

	prefs: ['webkit-slider-runnable-track', 'moz-range-track', 'ms-track'],

	init () {
		const
			sheet = document.createElement('style'),
			$rangeInput = $(document).find('.range-contain .range input');

		document.body.appendChild(sheet);

		$rangeInput.on('input', (e) => {
			sheet.textContent = this.getTrackStyle(e.target);
		});

		// Change input value on label click
		$('.range-labels li').on('click', function () {
			const index = $(this).index();

			$rangeInput.val(index + 1).trigger('input');
		});
	},

	getTrackStyle (el) {
		const
			$optionCount = $('.range-labels li').length,
			curVal = el.value,
			val = (curVal - 1) * (100 / ($optionCount - 1)),
			curLabel = $('.range-labels').find(`li:nth-child(${curVal})`);

		let
			style = '',
			i = 0;


		// Set active label
		$('.range-labels li').removeClass('--active --selected');

		curLabel.addClass('--active --selected');
		curLabel.prevAll().addClass('--selected');

		// Change background gradient (the progress indicator)
		for (i = 0; i < this.prefs.length; i++) {
			style += `.range-contain .range input::-${this.prefs[i]} { background: linear-gradient(to right, #50c6db 0%, #50c6db ${val}%, #E7E7E7 ${val}%, #E7E7E7 100%)}'`;
		}

		style += `.range-contain .range {background: linear-gradient(to right, #50c6db 0%, #50c6db ${val}%, rgba(0,0,0,0) ${val}%, rgba(0,0,0,0) 100%)}`;

		return style;
	}
};
