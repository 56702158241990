<!-- eslint-disable vue/no-v-html -->
<template>
	<span
		class="icon vue-icon"
		:class="className"
		:color="color"
		aria-hidden="true"
		v-html="image"
	></span>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true
		},

		color: {
			type: String,
			default: ''
		},

		className: {
			type: String,
			default: ''
		}
	},

	computed: {
		image() {
			let image;
			try {
				image = require(`!svg-inline-loader?removeSVGTagAttrs=false!svgPath/${this.name}.svg`);
			} catch {
				// eslint-disable-next-line no-console
				console.warn(`Icon with name "${this.name}" couldn't be found`);
			}

			return image;
		}
	}
};
</script>
