import Vue from 'vue';
const CommsCenter = () => import('vue-components/comms-center/CommsCenter');

export default {
	init () {
		const targetEl = document.getElementById('comms-center');
		if (targetEl) {
			new Vue({
				components: {
					'comms-center': CommsCenter
				}
			}).$mount(targetEl);
		}
	}
};
