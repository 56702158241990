/**
 * Manages site wide notification banner.
 */

import $ from 'jquery';
import Cookies from 'cookies-js';

export default {
	init () {
		// Bind to close icon
		$('.notice__close, .banner__close').on('click', this.hide);
	},

	hide (e) {
		const
			bannerHash = this.dataset.hash,
			currentDate = new Date(),
			oneYear = (365 * 24 * 60 * 60 * 1000),
			expireDate = new Date(currentDate.getTime() + oneYear),
			bannerType = this.dataset.banner || '';

		Cookies.set(`banner-ack-${bannerHash}`, '_', { expires: expireDate });

		// If it's a standard banner
		if (bannerType === 'standard') {
			e.preventDefault();
			$(this).closest('.banner').slideUp(200);
		} else {
			// Else (if it's a site-wide banner)
			$('body').removeClass('--has-site-notif');

			$(this).closest('.notice').addClass('--inactive').fadeOut(300, function () {
				$(this).remove();
			});
		}
	}
};
