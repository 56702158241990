/**
 * Carousel Helper
 */

import $ from 'jquery';

export default {
	init () {
		const checkedSlide = $('.carousel__check:checked');
		const checkedIndex = checkedSlide.parent().index();
		const carousel = $('.carousel__wrap');

		if (carousel.length) {
			import(/* webpackChunkName: "slick-carousel" */ 'slick-carousel').then((slick) => {
				carousel.each((index, item) => {
					$(item).find('.carousel').slick({
						dots: false,
						speed: 200,
						infinite: false,
						arrows: true,
						autoplay: false,
						centerMode: true,
						centerPadding: '1rem',
						focusOnSelect: true,
						edgeFriction: 0.07,
						variableWidth: true,
						prevArrow: $(item).find('.carousel__prev'),
						nextArrow: $(item).find('.carousel__next'),
						initialSlide: checkedIndex
					});

					// Stop the anchor tag from bringing the user to the top of the page
					$('.carousel__nav').on('click', (e) => {
						e.preventDefault();
					});
				});
			});
		}
	}
};
