/**
 * Tooltips mobile behavior
 */

import $ from 'jquery';
import Vue from 'vue';
import Tooltip from 'vue-components/Tooltip';

export default {
	// Cache jQuery objects
	els: {
		document: $(document),
		touchTrigger: '.tooltip__trigger'
	},

	init () {
		this.els.document.on('touchstart', this.els.touchTrigger, (e) => {
			e.preventDefault();
			e.stopPropagation();

			if ($(e.target).parents('.tooltip').hasClass('--open')) {
				$(e.target).parents('.tooltip').removeClass('--open');
			} else {
				$('.tooltip').removeClass('--open');
				$(e.target).parents('.tooltip').addClass('--open');
			}
		});

		document.querySelectorAll('.tooltip-instance').forEach((tooltip) => {
			new Vue({
				el: tooltip,

				components: {
					Tooltip
				}
			});
		});
	},

	destroy () {
		this.els.document.off('touchstart', this.els.touchTrigger);
	}
};
