/**
 * Left Nav

	Haml Usage:

	// This file targets mainly an element with the ID #left-nav
	// Plus a menu open/close toggle button .js-left-nav-toggle
	// And also, the <main> element, since we have to modify its z-index on mobile

	// Really all it does is listen for clicks, and when it registers one, either change the body's z-index, delay, and add classes to make the menu slide out, or do that all in the reverse order.
	// The setTimeout functions are necessary because the z-index needs to be changed BEFORE the animation begins; otherwise it's choppy

 */

let leftNavEls = {};

// The function that closes the menu
const closeLeftNavMenu = () => {
	leftNavEls.menu.classList.toggle('left-nav-shown');
	leftNavEls.buttons.forEach((btn) => {
		btn.setAttribute('aria-expanded', 'false');
	});
	leftNavEls.buttons[0].focus();
	setTimeout(() => {
		leftNavEls.main.classList.toggle('no-filter');
		leftNavEls.aside.classList.add('hidden');
	}, 300);
};

// The function that opens the menu
const openLeftNavMenu = () => {
	leftNavEls.main.classList.toggle('no-filter');
	leftNavEls.aside.classList.remove('hidden');
	leftNavEls.buttons.forEach((btn) => {
		btn.setAttribute('aria-expanded', 'true');
	});
	setTimeout(() => {
		leftNavEls.menu.classList.toggle('left-nav-shown');
	}, 20);
};

const leftNavEventHandler = (event) => {
	event.preventDefault();

	// If the menu's already open, we wanna close it first, then remove the body class
	if (leftNavEls.main.classList.contains('no-filter')) {
		closeLeftNavMenu();

	// Otherwise, do it the other way around; add the body class, THEN open the menu
	} else {
		openLeftNavMenu();
	}
};

const eventListen = () => {
	// Add event listeners to any button that can open or close the menu
	leftNavEls.buttons.forEach((btn) => {
		btn.addEventListener('click', leftNavEventHandler);
	});

	// Let the escape key close the left nav menu when opened
	leftNavEls.aside.addEventListener('keyup', (event) => {
		if (event.which === 27 && leftNavEls.main.classList.contains('no-filter')) {
			closeLeftNavMenu();
		}
	});

	// When a navigation item is clicked, close the menu
	const menuLinks = document.querySelectorAll('.left-nav-content a');
	menuLinks.forEach((link) => {
		link.addEventListener('click', () => closeLeftNavMenu());
	});
};

export default {
	init () {
		leftNavEls = {
			main: document.querySelector('main'),
			buttons: document.querySelectorAll('.js-left-nav-toggle'),
			menu: document.querySelector('#left-nav'),
			aside: document.querySelector('.layout__sidebar')
		};

		if (leftNavEls.buttons.length && leftNavEls.aside) {
			eventListen();
		}
	}
};
