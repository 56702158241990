var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat__content" }, [
    _c(
      "div",
      { staticClass: "kitchensink" },
      [
        _vm._t("trigger", null, {
          toggle: _vm.toggleDriftChat,
          load: _vm.loadDrift,
          show: _vm.showDrift,
          open: _vm.openDrift,
          hide: _vm.hideDrift,
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }