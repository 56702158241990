/**
 * Overlay
 */

import $ from 'jquery';

export default {
	els: {
		html: $('html'),
		overlay: $('.overlay')
	},

	experiences: [
		'full_screen',
		'menu',
		'shelf',
		'search',
		'modal'
	],

	open (experience = 'modal') {
		if (experience === 'modal' || experience === 'full_screen') {
			this.els.html.addClass('modal-open');
		}

		if (experience === 'menu') {
			this.els.html.addClass('menu-modal-open');
		}

		if (experience === 'search') {
			this.els.html.addClass('search-open');
		}

		if (this.experiences.indexOf(experience) >= 0) {
			this.els.overlay.addClass(`--${experience}`);
			this.els.html.addClass('--overlay --keep_chrome');
		}
	},

	close () {
		this.els.html.removeClass('--overlay --keep_chrome');

		this.els.html.removeClass('modal-open menu-modal-open search-open');

		$.each(this.experiences, (index, item) => {
			this.els.overlay.removeClass(`--${item}`);
		});
	},

	isShelfOpen () {
		return this.els.html.hasClass('--overlay') && this.els.html.hasClass('shelf-open');
	},

	isModalOpen () {
		return this.els.html.hasClass('--overlay') && (this.els.html.hasClass('modal-open') || this.els.html.hasClass('menu-modal-open'));
	}
};
