import request from 'browser-request';
import Vue from 'vue';

const context = document.getElementById('notification-context');
const guid = document.getElementById('guid-context');

const notificationComponent = {
	data: {
		active: false,
		notification: {
			subject_line: '',
			notices: [
				{
					delivery_id: 0,
					title: '',
					subtitle: '',
					link: '#'
				}
			]
		}
	},

	beforeDestroy () {
		clearInterval(this.interval);
	},

	created () {
		this.fetchNotices();
		this.poll();
	},

	methods: {
		poll () {
			this.interval = setInterval(() => {
				this.fetchNotices();
			}, 600000); // Updated to 10 minutes per TECH-63 as 10 seconds was quickly filling up logs
		},

		fetchNotices() {
			const requestObject = {
				type: context.content
			};

			if (guid) {
				requestObject.guid = guid.content;
			}

			request({
				url: '/notifications.json',
				qs: requestObject,
				json: true
			}, (error, response, body) => {
				if (!error && body.notification) {
					this.resolveResponse(body.notification);
				}
			});
		},

		show () {
			this.active = true;
		},

		dismiss () {
			this.active = false;

			request({
				url: '/notifications/dismiss',
				body: {
					'ids': this.notification.notices.map(key => key.delivery_id)
				},
				json: true,
				headers: {
					'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN
				},
				method: 'delete'
			}, (error, response, body) => {
				if (!error && body.notification) {
					this.resolveResponse(body.notification);
				}
			});
		},

		resolveResponse(body) {
			if (body.notices.length > 0) {
				this.notification = body;
				this.show();
			}
		}
	}
}

export default {
	init () {
		if (context) {
			if (guid && document.getElementById('notice-banner')) {  // show banner, not the notice list
				new Vue(notificationComponent).$mount('#notice-banner');
			} else {
				new Vue(notificationComponent).$mount('#notice-list');
			}
		}
	}
};
