var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("DriftLauncher", {
        on: {
          "show-support-shell-trigger": _vm.showSupportShellTrigger,
          "did-load": _vm.didLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function ({ load }) {
              return [
                _c(
                  "button",
                  {
                    attrs: {
                      type: "submit",
                      disabled: _vm.loading,
                      "data-working": _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return (() => _vm.handleClick(load)).apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _c("Icon", { attrs: { name: "comment_ellipsis" } }),
                    _vm._v("\n\t\t\t\t  SALES CHAT\n\t\t\t"),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }