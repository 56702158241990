import currencyHelper from 'helpers/currencies';

/*
 Inc v1.0.0
 (c) 2014 Depth Development. http://depthdev.com
 License: Apache 2.0
*/

const increment = (obj) => {
	const elem = obj.elem;
	const input = (elem.nodeName.toLowerCase() === 'input') ? true : false;
	const value = parseFloat(elem.getAttribute('data-inc-value')) || 0;
	const duration = parseInt(elem.getAttribute('data-inc-duration'), 10) || 0;
	const delay = parseInt(elem.getAttribute('data-inc-delay'), 10) || 0;
	const decimal = parseInt(elem.getAttribute('data-inc-decimal'), 10) || 0;
	const currencyCode = elem.getAttribute('data-inc-currency-code') || '';
	const speed = parseInt(elem.getAttribute('data-inc-speed'), 10) || 30;
	let count = 0;
	const increment = value / (duration / speed);
	let interval = null;
	const run = () => {
		count += increment;

		if (count >= value) {
			count = value;
			clearInterval(interval);
		}

		let newValue = count.toFixed(decimal);
		if (currencyCode) {
			newValue = currencyHelper.formatAsCurrency(newValue, currencyCode);
		}

		if (input) {
			elem.value = newValue;
		} else {
			elem.innerHTML = newValue;
		}
	};
	setTimeout(() => {
		interval = setInterval(run, speed);
	}, delay);
}

export default {
	init () {
		document.querySelectorAll('.counter-animation').forEach((el) => {
			increment({
				elem: el
			});
		});
	},

	increment (obj) {
		increment(obj);
	}
};
