/**
 * Radio block
 *
 * Usage:

 */

import $ from 'jquery';

export default {
	init () {
		this.bindIt();

		$(document).on('modal:open', () => {
			this.bindIt('.modal');
		});

		$(document).on('select-list:change', () => {
			setTimeout(() => {
				this.bindIt();
			}, 1000);
		});

		$(document).on('select-list:open', () => {
			this.bindIt();
		});
	},

	bindIt (passedContext) {
		const
			$radioToListenTo = $('input', '.radio_block'),
			context = (typeof passedContext !== 'undefined') ? passedContext : 'html';

		$radioToListenTo.each((i, el) => {
			if ($(el).is(':checked')) {
				$(el).parent()
					.addClass('--selected')
					.siblings()
					.removeClass('--selected');
			}
		});

		$radioToListenTo.on('change', (e) => {
			$(e.currentTarget).parent()
				.addClass('--selected')
				.siblings()
				.removeClass('--selected');
		});

		if (context) {
			$('.radio_block__content-wrap', context).each((i, el) => {
				const
					$radioToListenTo = $(`input[name="${$(el).attr('for')}"]:radio`),
					$theContentWrap = $(el);

				$radioToListenTo.on('change', (e) => {
					$(`[for="#${$(e.currentTarget).attr('id')}"]`, $theContentWrap)
						.addClass('--active')
						.siblings()
						.removeClass('--active');
				});
			});
		}
	}
};
