import { debounce } from 'helpers/debounce';

export default {
	elements: {
		links: NodeList
	},

	init () {
		this.elements.links = document.querySelectorAll('.nav-list a');

		const clickEvent = (e) => {
			this.elements.links.forEach((otherLinks) => {
				otherLinks.classList.remove('--active');
			});
			e.target.classList.add('--active');
		};

		this.elements.links.forEach((link) => {
			link.addEventListener('click', clickEvent, false);

			window.addEventListener('scroll', debounce(() => {
				const height = window.scrollY;

				this.elements.links.forEach((otherLinks) => {
					const section = document.querySelector(otherLinks.hash);

					if (!section) {
						return;
					}

					if (
						section.offsetTop <= height &&
						section.offsetTop + section.offsetHeight > height
					) {
						otherLinks.classList.add('--active');
					} else {
						if (otherLinks.classList.contains('--active')) {
							otherLinks.classList.remove('--active');
						}
					}
				});
			}, 25));
		});
	}
};
