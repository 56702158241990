/**
 * Click to Copy Helper
 */

import notif from 'components/notification';

export default {
	init () {
		const self = this;
		const triggers = document.querySelectorAll('[data-click-to-copy]');

		triggers.forEach((trigger) => {
			trigger.addEventListener('click', (e) => {
				e.stopPropagation();
				e.preventDefault();
				const copyWrapper = e.target.closest('[data-click-to-copy]');
				const text = copyWrapper.dataset.clickToCopy;
				const message = copyWrapper.dataset.clickToCopyMessage;

				self.createTemporaryInput(text, message);
			});
		});
	},

	// Create temporary input to be able to select and copy text
	createTemporaryInput (text, message) {
		const tempInput = document.createElement('input');
		tempInput.setAttribute('type', 'text');
		tempInput.setAttribute('value', text);
		document.body.appendChild(tempInput);

		tempInput.select();
		document.execCommand('copy');
		tempInput.remove();

		if (message) {
			notif.show({
				message,
				type: 'success',
				float: true
			});
		}
	}
};
