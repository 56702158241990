/**
 * Show a banner to IE users
 */

export default {
	els: {
		body: document.querySelector('body')
	},

	init () {
		const agent = window.navigator.userAgent;
		const isIe = agent.indexOf('MSIE');

		if (isIe > 0 || !!navigator.userAgent.match(/Trident\/7\./)) {
			this.showBanner();
		}
	},

	showBanner () {
		const template = `
			<div id="browser-notice" class="kitchensink text-center --error" style="position: fixed; z-index: 100; bottom: 0; left: 0; width: 100%; background: #ef4e65; padding: 20px;">
				<p class="--mw-100" style="color: #fff; margin: 0; padding: 0 20px;">Heads up! We don't support your browser and some things might not work properly. For the best experience, <a href="//outdatedbrowser.com" target="_blank" style="color: #fff; font-weight: 500; text-decoration: underline;">check&nbsp;out&nbsp;these&nbsp;browsers</a>.</p>

				<a href="#" style="display: block; position: absolute; right: 20px; top: 26px;" id="close-browser-notice">
					<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
						<path d="M7.7,6.3,5.4,4,7.7,1.7A.967.967,0,0,0,7.7.3.967.967,0,0,0,6.3.3L4,2.6,1.7.3A.967.967,0,0,0,.3.3a.967.967,0,0,0,0,1.4L2.6,4,.3,6.3a.967.967,0,0,0,0,1.4A.908.908,0,0,0,1,8a.908.908,0,0,0,.7-.3L4,5.4,6.3,7.7a.967.967,0,0,0,1.4,0A.967.967,0,0,0,7.7,6.3Z" fill="#fff"/>
					</svg>
				</a>
			</div>

			<style>.browser-notice-open .support-shell-container { bottom: 79px; }</style>
		`;

		this.els.body.insertAdjacentHTML('beforeend', template);
		this.els.body.classList.add('browser-notice-open');
		this.handleClosing();
	},

	handleClosing () {
		const close = document.getElementById('close-browser-notice');

		close.addEventListener('click', (event) => {
			event.preventDefault();
			this.els.body.classList.remove('browser-notice-open');
			document.getElementById('browser-notice').classList.add('hidden');
		});
	}
};
