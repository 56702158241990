/**
 * Adds a spinner for buttons
 *
 * Usage:

 <button data-spin>I will spin once my parent form is submitted</button>
 */

import $ from 'jquery';

export default {

	// Kick things off
	init (scope = 'body') {
		const $button = $(scope).find('[data-spin]');
		const parentForm = $button.parents('form');

		parentForm.on('submit', (e) => {
			$button.attr('data-working', true);
			$button.attr('disabled', true);

			if (!parentForm.valid()) {
				$button.removeAttr('data-working');
				$button.removeAttr('disabled', true);
			}
		});

		// Re-instantiate when a modal opens or closes in order to bind to the buttons
		$(document).on('modal:open', () => {
			this.init('.modal');
		});
		$(document).on('modal:close', () => {
			this.init();
		});
	}
};
