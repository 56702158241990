/**
 * Tab navigation
 *
 * Simple tabbing usage:

	<div class="tab-nav">
 		<a href="#" class="tab-nav__link" data-opens="panel1">Open panel</a>
		<a href="#" class="tab-nav__link" data-opens="panel2">Open panel</a>
	</div>

	<div id="panel1" class="tab-content"></div>
	<div id="panel2" class="tab-content hidden"></div>
 */

export default {
	elements: {
		container: document.querySelector('.tab-nav'),
		tabs: document.querySelectorAll('.tab-nav__item'),
		anchors: document.querySelectorAll('.tab-nav a'),
		leftNav: document.querySelector('.layout__nav-left'),
		tabContent: document.querySelectorAll('.tab-content')
	},

	init () {
		this.setupScrollShadows();
		this.handleNavClicks();
	},

	// Adds shadows to left or right of nav when horizontally scrolling it on small screens
	setupScrollShadows () {
		if (this.elements.leftNav && this.elements.container) {
			this.elements.container.addEventListener('scroll', (e) => {
				const cur = e.target.scrollLeft;

				if (cur === 0) {
					// if scrolled all the way to the left
					this.elements.leftNav.classList.add('shadow-right');
					this.elements.leftNav.classList.remove('shadow-left');
				} else {
					const max = e.target.scrollWidth - e.target.parentElement.offsetWidth + 20;

					if (cur === max) {
						// if scrolled all the way to the right
						this.elements.leftNav.classList.add('shadow-left');
						this.elements.leftNav.classList.remove('shadow-right');
					} else {
						// if somewhere in the middle
						this.elements.leftNav.classList.add('shadow-right', 'shadow-left');
					}
				}
			});
		}
	},

	handleNavClicks () {
		this.elements.anchors.forEach((element) => {
			element.addEventListener('click', (e) => {
				// Allow clicking through to href on data-clickthrough elements
				if (!e.target.getAttribute('data-clickthrough')) {
					e.preventDefault();
				}

				// data attr should be ID of panel to show
				const idToOpen = e.target.getAttribute('data-opens');

				// if there's a data-opens attr, we're gonna show/hide content here
				if (idToOpen) {
					this.switchPanel(idToOpen);
					this.updateTabs(element);
				}
			});
		});
	},

	switchPanel (id) {
		const element = document.getElementById(id);

		if (element) {
			// Hide all other panels
			this.elements.tabContent.forEach((content) => {
				content.classList.add('hidden');
			});

			element.classList.remove('hidden');
		} else {
			throw 'trying to show something that\'s not in the DOM';
		}
	},

	updateTabs (anchor) {
		const tab = anchor.parentElement;

		this.elements.tabs.forEach((element) => {
			element.classList.remove('active');
		});

		tab.classList.add('active');
	}
};
