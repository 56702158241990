/**
 * Global header navigation & search visibility functionality
 */

import overlay from './overlay';

export default {
	els: {},

	curHighlight: null,

	// Kick things off
	init () {
		// Setting the elements here so we can test them
		this.els.siteSearch = document.getElementById('search-sites');
		this.els.closeSearch = document.getElementById('close-search');

		if (this.els.siteSearch) {
			this.els.siteSearch.onfocus = this.showSearch;
			this.els.closeSearch.onclick = this.closeSearch;
			this.els.siteSearch.addEventListener('keydown', this.closeSearch);
			this.els.closeSearch.addEventListener('keydown', this.closeSearchButton);
		}
	},

	showSearch () {
		overlay.open('search');
	},

	closeSearch (e) {
		if (
			(e.type === 'keydown' && e.key === 'Escape') ||
			e.type === 'click'
		) {
			overlay.close();
		}
	},

	closeSearchButton (e) {
		// If on search close button and tab away or press enter, close the search
		if (e.key === 'Enter' || e.key === 'Tab') {
			overlay.close();
		}
	}
};
