import fwHighchartsDefaults from 'helpers/highcharts';
fwHighchartsDefaults.init();


const renderDonutChart = (data = [], target = '', countType = 'request') => {

	// eslint-disable-next-line no-inline-comments
	import(/* webpackChunkName: "highcharts" */ 'highcharts').then((Highcharts) => {

		const donutChartContainer = document.querySelector(target);

		//Get only cached percentage from the incoming data, to display as the title
		const chartTitle = data[0].y.toFixed(1);

		if (data && donutChartContainer) {

			//Set a couple of default colors
			data[0].color = data[0].color || '#51bb7b';
			data[1].color = data[1].color || '#ef4e65';
			data[0].altColor = data[0].altColor || '#257048';
			data[1].altColor = data[1].altColor || '#8c2738';

			//Render the chart with all the below options to the ID specified
			Highcharts.chart(donutChartContainer, {
				chart: {
					type: 'pie'
				},
				title: {
					text: `${chartTitle}%`,
					verticalAlign: 'middle',
					y: 11,
					style: {
						opacity: 0,
						fontSize: '3.5rem',
						fontWeight: 400,
						color: data[0].altColor,
						animation: 'fade_in_up .5s ease-out forwards',
						animationDelay: '.4s'
					}
				},
				tooltip: {
					headerFormat: null,
					formatter () {
						let templateLiteral = null;
						if (this.point.count) {
							// eslint-disable-next-line no-inline-comments
							templateLiteral = /*html*/`
								<div class="pie-tooltip">
									<div style="color: ${this.point.altColor}">${this.point.name}&emsp;${this.point.y}%</div>
									<div>${String(this.point.count).replace(/(\d)(?=(\d{3})+$)/g, '$1,')} ${countType}${this.point.count > 1 ? 's' : ''}</div>
								</div>
							`;
						} else {
							// eslint-disable-next-line no-inline-comments
							templateLiteral = /*html*/`
								<div class="pie-tooltip">
									<div style="color: ${this.point.altColor}">${this.point.name}&emsp;${this.point.y}%</div>
								</div>
							`;
						}
						//Function in string above adds commas to 4+ digit numbers, e.g., 1234567 => 1,234,567
						return templateLiteral;
					},
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					style: {
						fontSize: '1.8rem',
						fontWeight: 400,
						padding: 40,
						color: '#333'
					}
				},
				plotOptions: {
					pie: {
						innerSize: '70%'
					},
					series: {
						dataLabels: {
							enabled: false
						},
						point: {
							events: {
								mouseOver (e) {
									this.options.oldColor = e.target.color;
									this.graphic.attr('style', 'transition: fill .15s ease');
									this.graphic.attr('fill', this.altColor);
								},
								mouseOut () {
									this.graphic.attr('fill', this.options.oldColor);
								}
							}
						}
					}
				},
				series: [{
					name: 'Caching',
					borderWidth: 5,
					data
				}]
			});
		} else if (target) {
			// eslint-disable-next-line no-console
			console.warn('Could not render donut chart. Either no data was supplied, or no target element could be found.');
		}
	});
};

export default {
	init (data, target, countType) {
		renderDonutChart(data, target, countType);
	}
}
