import request from 'browser-request';
import Vue from 'vue';

const productUpdatesComponent = {
	data () {
		return {
			query: {
				limit: 10
			},
			updates: []
		}
	},

	created () {
		this.fetchUpdates();
	},

	methods: {
		tagClass (type) {
			switch (type) {
				case 'feature':
					return '--primary';
					break;
				case 'release-notes':
					return '--warning';
					break;
				case 'tips-tricks':
					return '--error';
				default:
					return '';
			}
		},

		fetchUpdates () {
			request({
				url: '/product_updates',
				qs: this.query,
				json: true
			}, (error, response, body) => {
				if (!error) {
					this.updates = body.updates;
					this.updateLastRead();
				}
			});
		},

		updateLastRead () {
			request({
				url: '/product_updates/',
				json: true,
				headers: {
					'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN
				},
				method: 'put'
			}, (error, response, body) => {
				if (!error && response.statusCode === 200) {
					let badge = document.getElementById('unread-badge');
					let unread = document.getElementsByClassName('dropdown__notification');
					if (badge) {
						badge.parentNode.removeChild(badge);
					}
					if (unread.length > 0) {
						Array.from(unread).forEach(countBadge => countBadge.parentNode.removeChild(countBadge));
					}
				}
			});
		}
	}
}

export default {
	init () {
		const flyout = document.getElementById('product-updates-flyout');

		if (flyout) {
			flyout.addEventListener('flownout', (event) => {
				new Vue(productUpdatesComponent).$mount('#product-updates');
			}, {
				once: true
			});
		}
	}
};
