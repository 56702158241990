/**
 * Helpers
 */

import header from 'components/header';
import dropdowns from 'components/dropdowns';
import dropdownsShelf from 'components/dropdowns-shelf';
import tabNav from 'components/tab-nav';
import modal from 'components/modal';
import selectList from 'components/select-list';
import radioBlock from 'components/radio-block';
import rangeSlider from 'components/range-slider';
import typeahead from 'components/typeahead';
import spinner from 'components/spinner';
import switcher from 'components/switcher';
import carousel from 'components/carousel';
import notification from 'components/notification';
import noticeList from 'components/notice-list';
import remoteLink from 'components/remote-link';
import toggles from 'components/toggles';
import statusBanner from 'components/status-banner';
import tooltips from 'components/tooltips';
import clickToCopy from 'components/click-to-copy';
import flyout from 'components/flyout';
import productUpdates from 'components/product-updates';
import supportShell from 'components/support-shell';
import counter from 'components/counter';
import browserNotice from 'components/browser-notice';
import leftNav from 'components/left-nav';
import mobileNav from 'components/mobile-nav';
import commsCenter from 'components/comms-center';

export default (function () {
	header.init();
	dropdowns.init();
	dropdownsShelf.init();
	tabNav.init();
	modal.init();
	selectList.init();
	radioBlock.init();
	rangeSlider.init();
	typeahead.init();
	spinner.init();
	switcher.init();
	carousel.init();
	notification.init();
	noticeList.init();
	remoteLink.init();
	toggles.init();
	statusBanner.init();
	tooltips.init();
	clickToCopy.init();
	flyout.init();
	productUpdates.init();
	supportShell.init();
	counter.init();
	browserNotice.init();
	leftNav.init();
	mobileNav.init();
	commsCenter.init();
}());
