/**
 * Toggle block
 *
 * Usage:

 */

import $ from 'jquery';

export default {
	init () {
		this.bindIt();

		$(document).on('modal:open', () => {
			this.bindIt('.modal');
		});
	},

	bindIt (context) {
		$('.toggles__content', context).each((index, item) => {
			const $toggleToListenTo = $(`input[name="${$(item).attr('for')}"]`);
			const $hiddenItem = $(`.toggles__content[for="${$toggleToListenTo.attr('name')}"]`);

			if ($toggleToListenTo.data('opens') === true) {
				$hiddenItem.attr('hidden', false);
			} else {
				$hiddenItem.attr('hidden', true);
			}

			if ($hiddenItem.data('opened')) {
				$hiddenItem.attr('hidden', false);
			}

			$(document).trigger('toggles:change', {
				value: $toggleToListenTo.val(),
				name: $toggleToListenTo.attr('name')
			});

			$toggleToListenTo.on('change', this.change);
		});
	},

	change (e) {
		if ($(e.currentTarget).data('opens') === true) {
			$(`.toggles__content[for="${$(e.currentTarget).attr('name')}"]`).attr('hidden', false);
		} else {
			$(`.toggles__content[for="${$(e.currentTarget).attr('name')}"]`).attr('hidden', true);
		}

		$(document).trigger('toggles:change', {
			value: $(e.currentTarget).val(),
			name: $(e.currentTarget).attr('name')
		});
	}
};
