/**
 * Helper to submit forms remotely with Ajax
 */

import $ from 'jquery';
import notif from 'components/notification';
import modal from 'components/modal';

let defaults = {
	type: 'POST',
	url: '/',
	data: '',
	showErrors: true,
	beforeSend: null,
	complete: null,
	closeModal: true
};

export default {
	submit (params, callback, errorback) {
		// Merge defaults with options
		const options = $.extend({}, defaults, params);

		$.ajax({
			headers: { 'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN },
			dataType: 'json',
			type: options.type,
			url: options.url,
			data: options.data,
			beforeSend: options.beforeSend,
			complete: options.complete,
			error (error) {
				let message = '';

				if (options.showErrors) {
					if (typeof error.action !== 'undefined') {
						if (typeof error.action.alert !== 'undefined') {
							message = error.action.alert;
						} else {
							message = 'An error occurred. Please try again.';
						}
					} else {
						message = 'An error occurred. Please try again.';
					}

					// Show error message
					notif.show({
						message,
						type: 'error',
						float: true
					});
				}

				if (options.closeModal) {
					// Close modal
					modal.close();
				}

				if (errorback) {
					errorback(error);
				}
			},
			success (data) {
				// Make sure no notifications are shown
				notif.hide();

				if (
					(typeof data.result !== 'undefined' && data.result !== 'ok') ||
					(typeof data.status !== 'undefined' && data.status !== 'success')
				) {
					this.error(data);
				} else {
					if (typeof data.action !== 'undefined') {
						if (typeof data.action.redirect !== 'undefined') {
							window.location.href = data.action.redirect;
						}
					}
					callback(data);
				}
			}
		});
	}
}
