var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "icon vue-icon",
    class: _vm.className,
    attrs: { color: _vm.color, "aria-hidden": "true" },
    domProps: { innerHTML: _vm._s(_vm.image) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }