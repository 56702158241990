var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "			<span class=\"label --green\">Org</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"className") || (depth0 != null ? lookupProperty(depth0,"className") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"className","hash":{},"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":26}}}) : helper)))
    + "\">\n	<a href=\"#\" data-id=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-type=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">\n		"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":14}}}) : helper))) != null ? stack1 : "")
    + "\n\n		<span class=\"text-left\">\n			<strong>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</strong>\n			<small>("
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + ")</small>\n		</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"is_org") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "\n		<span class=\"button --secondary typeahead__button\">Invite</span>\n	</a>\n</li>\n";
},"useData":true});