/**
 * Flyout

	Haml Usage:

	// Give your flyout element a unique ID
	#product-updates.flyout

	// Add `js-flyout-trigger` class to trigger
	// & set the data-flyout attr equal to ID of flyout you want to open
	= link_to "Product Updates", "#", class: "js-flyout-trigger", data: { flyout: "product-updates" }

	// This component emits a custom event on the flyout element when it's opened, so you can listen to it in other files:
	const flyout = document.getElementById('product-updates-flyout');
	flyout.addEventListener('flownout', (event) => {...}
 */

const els = {
	html: document.querySelector('html'),
	flyoutTriggers: document.querySelectorAll('.js-flyout-trigger'),
	flyouts: document.querySelectorAll('.flyout')
};

const eventListen = () => {
	// allow clicking "off" to close
	document.addEventListener('click', closeFlyout);

	// allow escape key to close
	document.addEventListener('keydown', (event) => {
		if (event.keyCode === 27) {
			closeFlyout();
		}
	});

	els.flyoutTriggers.forEach((trigger) => {
		trigger.addEventListener('click', openFlyout);
	});

	els.flyouts.forEach((flyout) => {
		// prevent clicks inside flyout from closing it
		flyout.addEventListener('click', (e) => e.stopPropagation());

		// bind all close icons to close
		const closeIcon = flyout.querySelector('.flyout__close');
		closeIcon.addEventListener('click', closeFlyout);
	});
};

const openFlyout = (event) => {
	// Ensures dropdowns are closed if they are the target
	event.target.blur();
	event.stopPropagation();
	event.preventDefault();

	// grab ID of flyout to open from data-flyout attr on target
	const targetFlyout = document.getElementById(event.target.dataset.flyout);

	if (targetFlyout) {
		targetFlyout.classList.add('--open');
		els.html.classList.add('flyout-open');
		emitEvent(targetFlyout);
	}
};

// emits a custom event when opened
const emitEvent = (element) => {
	const event = document.createEvent('CustomEvent');
	event.initCustomEvent('flownout', true, true);
	element.dispatchEvent(event);
};

const closeFlyout = () => {
	els.flyouts.forEach((flyout) => {
		flyout.classList.remove('--open');
	});
	els.html.classList.remove('flyout-open');
};

export default {
	init () {
		if (els.flyoutTriggers.length) {
			eventListen();
		}
	}
};
