import Vue from 'vue';
const GlobalNavigation = () => import('vue-components/mobile-navigation/GlobalNavigation.vue');

export default {
	init () {
		const mobileNavEl = document.getElementById('nav-mobile');
		if (mobileNavEl) {
			new Vue({
				components: {
					'mobile_nav': GlobalNavigation
				}
			}).$mount(mobileNavEl);
		}
	}
};
