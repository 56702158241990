/**
 * Helper to submit forms remotely with Ajax
 */

import $ from 'jquery';
import modal from 'components/modal';

const action = {
	els: {
		$modal: $('#confirm-modal')
	},

	confirmed: false,
	callback: false,
	heading: 'Are you sure you want to do this?',
	copy: 'This action is permanent! Please be&nbsp;careful.',
	confirm: 'Yes, please!',
	deny: 'Nevermind!',

	destroy () {
		this.callback = false;

		this.els.$modal.find('#confirm').off('click.confirm-modal');
		this.els.$modal.find('#deny').off('click.confirm-modal');
	},

	ask (callback) {
		this.callback = callback;

		// Unspin button
		this.els.$modal.find('#confirm').attr('data-working', false);

		this.els.$modal.find('#modal_title').html(this.heading);
		this.els.$modal.find('#modal_copy').html(this.copy);
		this.els.$modal.find('#confirm').html(this.confirm);
		this.els.$modal.find('#deny').html(this.deny);

		modal.close();

		setTimeout(() => {
			modal.open('#confirm-modal');
		}, 500);

		this.els.$modal.find('#confirm').on('click.confirm-modal', () => {
			this.els.$modal.find('#confirm').off('click.confirm-modal');
			this.els.$modal.find('#deny').off('click.confirm-modal');

			this.confirmed = true;

			this.callback(this.confirmed);

			// Spin button
			this.els.$modal.find('#confirm').attr('data-working', true);
		});

		this.els.$modal.find('#deny').on('click.confirm-modal', () => {
			this.confirmed = false;

			this.callback(this.confirmed);

			modal.close();
			this.els.$modal.find('#confirm').off('click.confirm-modal');
			this.els.$modal.find('#deny').off('click.confirm-modal');
		});
	}
};

export default action;
