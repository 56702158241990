<template>
	<div>
		<DriftLauncher @show-support-shell-trigger="showSupportShellTrigger" @did-load="didLoad">
			<template v-slot:trigger="{ load }">
				<button
					type="submit"
					:disabled="loading"
					:data-working="loading"
					@click.prevent="() => handleClick(load)"
				>
					<Icon name="comment_ellipsis" />
					&nbsp; SALES CHAT
				</button>
			</template>
		</DriftLauncher>
	</div>
</template>

<script>
import DriftLauncher from 'vue-components/chat-bubbles/DriftLauncher';
import Icon from 'vue-components/Icon';

export default {
	components: {
		DriftLauncher,
		Icon
	},

	data() {
		return {
			loading: false
		}
	},

	methods: {
		handleClick(load) {
			this.loading = true;
			load();
		},

		didLoad() {
			const supportShellContainer = document.querySelector('.support-shell-container');
			const supportShellTrigger = document.querySelector('.support-shell-trigger');
			supportShellTrigger.style.display = 'none';
			supportShellContainer.classList.remove('open');
			this.loading = false;
		},

		showSupportShellTrigger() {
			const supportShellTrigger = document.querySelector('.support-shell-trigger');
			if (window.getComputedStyle(supportShellTrigger).display === 'none') {
				supportShellTrigger.style.display = 'block';
			}
		}
	}
};
</script>
