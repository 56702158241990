/**
 * Number counter
 */

class Counter {
	constructor (element) {
		this.element = element;
		this.multipliedValue = 0;
		this.minValue = 0;
		this.events();

		if (this.elements.input.min) {
			this.minValue = this.elements.input.min;
		}
	}

	get elements () {
		return {
			decrementor: this.element.querySelector('[data-decrement]'),
			incrementor: this.element.querySelector('[data-increment]'),
			input: this.element.querySelector('.counter__input'),
			output: this.element.querySelector('[data-multiplier]'),
			textDisplay: this.element.querySelector('.counter-text'),
			box: this.element.querySelector('.counter__box')
		};
	}

	get multiplier () {
		return this.elements.output.dataset.multiplier;
	}

	get counterStartValue () {
		return this.elements.output.dataset.start;
	}

	// Listen for button clicks & input change
	events () {
		this.elements.decrementor.addEventListener('click', (event) => {
			this.changeCount(event, 'subtract');
		});

		this.elements.incrementor.addEventListener('click', (event) => {
			this.changeCount(event, 'add');
		});

		this.elements.input.addEventListener('input', (event) => {
			this.changeDisplayValue(event);
		});

		document.addEventListener('counterUpdateNeeded', () => {
			this.changeDisplayValue();
		});
	}

	// Update hidden input's value
	changeCount (event, type) {
		event.preventDefault();
		const number = Number(this.elements.input.value);
		const newValue = type === 'subtract' ? number - 1 : number + 1;

		// A little validation to ensure you can't go lower than the min attribute
		if (newValue >= this.minValue) {
			this.elements.input.value = newValue;
			this.changeDisplayValue();
		} else {
			// and if the value IS lower (from manual input), set it back to the min
			this.elements.input.value = this.minValue;
			this.elements.box.classList.remove('--error');
		}
	}

	checkValidity () {
		// Check validity based on min attribute
		if (this.elements.input.validity.valid) {
			this.elements.box.classList.remove('--error');
		} else {
			this.elements.box.classList.add('--error');
		}
	}

	// Update text displayed under counter
	changeDisplayValue () {
		const difference = this.elements.input.value - this.counterStartValue + 1;
		this.multipliedValue = difference * this.multiplier;

		this.checkValidity();

		// Display the value to the user
		this.elements.output.innerHTML = this.multipliedValue;

		// Show value to user only if it's greater than the data-start
		if (this.multipliedValue >= this.multiplier) {
			this.elements.textDisplay.classList.add('displayed');
		} else {
			this.elements.textDisplay.classList.remove('displayed');
		}

		// Emit a custom event with the value to be able to listen to in other files
		const customEvent = new CustomEvent('counterChange', {
			detail: {
				value: this.elements.input.value,
				additional: Math.max(0, this.multipliedValue)
			}
		});

		this.elements.input.dispatchEvent(customEvent);
	}
}

export default {
	init () {
		const counters = document.querySelectorAll('.counter-wrap');

		if (counters.length) {
			// Create a new instance of Counter for each counter on the page
			counters.forEach((counter) => new Counter(counter));
		}
	}
};
